import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { Fragment } from 'react';
import Home from './pages/Home';
import Create from './pages/Create';
import Edit from './pages/Edit';
import Card from './pages/Card';
import ListNameCard from './pages/ListNameCard';
function App() {
    return (
        <Router >
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/create" element={<Create/>}/>
                    <Route path="/edit/:id" element={<Edit/>}/>
                    <Route path="/card/:id" element={<Card/>}/>
                    <Route path="/listnamecard" element={<ListNameCard/>}/>
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
