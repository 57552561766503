import styles from './Footer.module.scss';
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import logoMk from '../../../../assets/images/logoMK.svg'
import logoFace from '../../../../assets/images/logoFace.svg'
import logoIn from '../../../../assets/images/logoIn.svg'
import logoTele from '../../../../assets/images/logoTele.svg'
import logoGoogle from '../../../../assets/images/logoGoogle.svg'


function Footer() {

    return (
        <div className={styles.footer}>
            <div className={styles.logoFooter}>
                <img src={logoMk} />
            </div>
            <div className={styles.contentFooter}>
                <div className={styles.leftColFooter}>
                    <div className={styles.textFooter}>Công ty cổ phần MK Vision được thành lập vào tháng 7 năm 2020 và là thành viên của MK Group. Chúng tôi tập trung phát triển phần cứng và ứng dụng công nghệ camera thông minh tích hợp trí tuệ nhân tạo (AI camera). MK Vision cam kết đưa ra thị trường những dòng sản phẩm camera hiện đại và cao cấp với chất lượng quốc tế, phục vụ nhu cầu giám sát và quản lý đặc thù tại Việt Nam.</div>
                    <div className={styles.logoContactFooter}>
                        <img src={logoFace} className={styles.logoItemContact} />
                        <img src={logoIn} className={styles.logoItemContact} />
                        <img src={logoTele} className={styles.logoItemContact}/>
                        <img src={logoGoogle} className={styles.logoItemContact}/>
                    </div>
                    <div className={styles.copyRight}>@All right reserved 2023</div>
                </div>
                <div className={styles.contactFooter}>
                    <div  className={styles.colFooter}>
                        <div className={styles.coltitle}>Danh mục</div>
                        <div className={styles.colItem}>Trang chủ</div>
                        <div className={styles.colItem}>Về chúng tôi</div>
                        <div className={styles.colItem}>Quản lý</div>
                    </div>
                    <div  className={styles.colFooter}>
                        <div className={styles.coltitle}>Danh thiếp</div>
                        <div className={styles.colItem}>Danh thiếp của tôi</div>
                        <div className={styles.colItem}>Liên hệ hỗ trợ</div>
                        <div className={styles.colItem}>Câu hỏi hỗ trợ</div>
                    </div>
                    <div  className={styles.colFooter}>
                        <div className={styles.coltitle}>Liên hệ</div>
                        <div className={styles.colItem}>MKvision@gmail.com</div>
                        <div className={styles.colItem}>Số 9, ngõ 15 Duy Tân, Dịch Vọng Hậu, quận Cầu Giấy, Hà Nội</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
