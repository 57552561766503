import Profile from './Profile'
import Email from './Email'
import Phone from './Phone'
import Web from './Web'
import Address from './Address'
import { Spin } from 'antd';
import QRCode from "react-qr-code";
import { useEffect, useState } from 'react'
import { fetchGetFromAPI } from '../utils/fetchFromAPI';
import downloadIcon from '../assets/images/qrdownload.svg'
import * as downloadAsPng from 'save-svg-as-png';
import backgroundImage from '../assets/images/BackGround.png'



function Card() {
    const [data, setData] = useState();
    const [falieddata, setFalieddata] = useState(true);
    const [loading, setloading] = useState(true);


    useEffect(() => {
        const url = window.location.pathname
        const newUrl = url.split('/', 3)[2]
        const urlDecode = window.atob(newUrl);
        getData(urlDecode)
    }, []);

    const getData = async (urlDecode) => {
        const url = `/api/v1/card/${urlDecode}`
        const data = await fetchGetFromAPI(url)
        if (data) {
            setData(data)
            setFalieddata(false)
            setloading(false)
            window.scrollTo({top: '50px', behavior: 'smooth'});
        }
        else {
            setFalieddata(true)
            setloading(false)
        }
    }

    const downloadQRCode = () => {
        downloadAsPng.saveSvgAsPng(document.getElementById("qr-gen"), `${data.full_name}_qrcode`,{scale: 10})
    };
    
    return (
        <Spin spinning={loading}>
            <div  className="card" style={{backgroundImage:  `url(${backgroundImage})`}}>
              <div className="card-Item">
              <Profile data={data} falieddata={falieddata} />
                {falieddata ? null : <div style={{ borderTop: '1px solid #D0E0FF',borderBottom:'1px solid #D0E0FF',margin: '0 20px',paddingBottom: 15}}>
                    <div>
                        {falieddata ? null : <Email gmail={data?.email} />}
                        <Phone  phone={data?.phone} />
                        <Web fleet={data?.domain} />
                        {falieddata ? null : <Address  address={data?.address} />}
                    </div>
                </div>}
               
                {falieddata ? <><div className='card404'>404 error!</div>
                    <div className='cardnotfound'>Card not found</div></> : null}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 35px' }}>
                    <div style={{ height: "auto", maxWidth: 100, width: "100%", display: 'flex', alignItems: 'center', marginRight: '15px', }}>
                        <QRCode
                            id="qr-gen"
                            size={1000}
                            style={{ height: "auto", maxWidth: "100%", width: "100%", }}
                            value={window.location.href}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                    <div style={{
                        display: 'flex', width: '60%', height: 40, border: '1px solid #2E14CF', borderRadius: 8,
                        alignItems: 'center', padding: '6px 12px', marginTop: 30, cursor:'pointer', background: '#fff'
                    }} onClick={downloadQRCode}>
                        <img src={downloadIcon} style={{ width: 16, height: 16 }} />
                        <div style={{ color: '#2E14CF', marginLeft: 10, fontSize: 14, fontWeight: 600, }}>Tải QRCode</div>
                    </div>
                </div>
            </div>
        </Spin>
    );

}
export default Card;