import CardItem from '../../components/Card'
import HeaderLayout from '../../components/Layout/HeaderOnlyLayout';
import FooterLayout from '../../components/Layout/FooterOnlyLayout';

function Card() {

    const innerWidth = window.innerWidth;
    const checkToken = localStorage.getItem('token')

    return (
        <div >
            {checkToken ? <HeaderLayout></HeaderLayout> : null}
            <CardItem />
        </div>

    );
}
export default Card;
