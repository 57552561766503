import TooltipParagraph from '../utils/func'
import linkIcon from '../assets/images/gmaill.svg'


function Email(props){
    const sendEmail = (data) => {
        window.open(`mailto:${data}`);
     }
    return (
        <div onClick={() => sendEmail(props.phone)} className="about">
            <div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
                    <img src={linkIcon} />
                    <div style={{ marginLeft: 10, width: 180 }}>
                        <TooltipParagraph>
                            {props.gmail}
                        </TooltipParagraph>
                    </div>
                </div>
        </div>
    );
}
export default Email;