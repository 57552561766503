import styles from './ListNameCard.module.scss';
import HeaderLayout from '../../components/Layout/HeaderOnlyLayout';
import FooterLayout from '../../components/Layout/FooterOnlyLayout';
import { Button, Modal, Input, notification, Spin } from 'antd';
import * as downloadAsPng from 'save-svg-as-png'
import QRCode from "react-qr-code";
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import { fetchPostFromAPI, fetchDeleteFromAPI, fetchGetFromAPI, BASE_URL } from '../../utils/fetchFromAPI';
import gmailIcon from '../../assets/images/gmail.svg'
import phoneIcon from '../../assets/images/phone.svg'
import locationIcon from '../../assets/images/location.svg'
import editIcon from '../../assets/images/Edit.svg'
import downloadIcon from '../../assets/images/dowload.svg'
import backgroundImage from '../../assets/images/BackGround.png'
import DeleteIcon from '../../assets/images/delete.png'
import n404Icon from '../../assets/images/404.png'

function ListNameCard() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDelete, setIsModalDelete] = useState(false);
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');
    const [data, setData] = useState([]);
    const [arr, setArr] = useState([]);
    const [loading, setloading] = useState(false);
    const [searchVal, setSearchVal] = React.useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData(searchVal)
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchVal])

    useEffect(() => {
        if (window.localStorage.getItem('token')) {
            setToken(window.localStorage.getItem('token'))
            getData()
        }
        else {
            setIsModalOpen(true)
        }
    }, [window.localStorage.getItem('token')]);

    useEffect(() => {
        if (token !== '' && token !== null) {
            getData()
        }
    }, [token])

    const handleOk = async () => {
        const data = await fetchPostFromAPI('api/v1/auth', {
            "username": "admin",
            "password": password
        })
        if (data?.token) {
            setloading(true)
            window.localStorage.setItem("token", data.token);
            setIsModalOpen(false);
            setloading(false)
            setIsModalOpen(false)
        }
        else {
            setloading(false)
            openNotificationFail('Mã bảo mật không đúng')
        }
    };

    const openNotificationSuccess = (mess) => {
        notification.success({
            message: 'Notification',
            description: mess,
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    const openNotificationFail = (mess) => {
        notification.error({
            message: 'Notification',
            description: mess,
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    const changePassword = (event) => {
        setPassword(event.target.value)
    }
    const handleCancel = () => {
        setIsModalOpen(!isModalOpen);
        window.location.href = '/';
    };

    const handleCancelDelete = () => {
        setIsModalDelete(!isModalDelete);
    };


    const deleteList = async () => {
        for (let i = 0; i < arr.length; i++) {
            await handleDelete(arr[i].id)
        }
        setArr([])
        setIsModalDelete(false)
        getData()
    }

    const handleDelete = async (id) => {
        const url = `api/v1/card/${id}`
        const data = await fetchDeleteFromAPI(url, {
            headers: {
                'token': window.localStorage.getItem('token'),
            }
        })
        if (data?.result === "success") {
            openNotificationSuccess('Delete success')
            getData()
        }
        else if (data === 'jwt fail') {
            window.localStorage.removeItem('token')
        }
    }

    const handleDownload = (id, full_name) => {
        downloadAsPng.saveSvgAsPng(document.getElementById(`qr-gen${id}`), `${full_name}_qrcode`, { scale: 10 })
    };

    const getData = async (searchVal) => {
        let params = ''
        if (searchVal) {
            params = `name=${searchVal}`
        }
        setloading(true)
        const url = `/api/v1/card?${params}`
        const data = await fetchGetFromAPI(url, {
            headers: {
                'token': window.localStorage.getItem('token'),
            }
        })
        if (data !== 'jwt fail') {
            setData(data)
            setloading(false)
        }
        else if (data === 'jwt fail') {
            window.localStorage.removeItem('token')
        }
    }
    const onChangeCheckbox = (e) => {
        if (e.target.checked === true && e.target.attributes.idc.nodeValue && e.target.attributes.namec.nodeValue) {
            setArr(oldArray => [...oldArray, {
                id: e.target.attributes.idc.nodeValue,
                name: e.target.attributes.namec.nodeValue
            }])

        }
        else if (e.target.checked === false && e.target.attributes.idc.nodeValue && e.target.attributes.namec.nodeValue) {
            let newArr = arr.filter((item) =>
                item.id !== e.target.attributes.idc.nodeValue && item.name !== e.target.attributes.namec.nodeValue)
            setArr(newArr)
        }
    };

    const downLoadAll = () => {
        for (let i = 0; i < arr.length; i++) {
            handleDownload(arr[i].id, arr[i].name)
        }
    }


    const handleInput = (e) => {
        setSearchVal(e.target.value);
    }

    const handleClearBtn = () => {
        setSearchVal('');
    }

    const locationSearch = (data) => {
        window.open("//" + "google.com/search?q=" + data, '_blank');
    }

    const callMobile = (data) => {
        window.open(`tell:${data}`);
    }

    const sendEmail = (data) => {
        window.open(`mailto:${data}`);
    }

    const handleOkDelete = () => {
        deleteList()
    }

    const showPopUpDelete = () => {
        setIsModalDelete(true)
    }



    return (
        <Spin spinning={loading}>
            <div>
                <Modal width={'400px'} className='title-warning' title="Xác nhận xóa các mục đã chọn!" cancelText={'Hủy'} okText={'Xóa'} open={isModalDelete} onOk={handleOkDelete} onCancel={handleCancelDelete}>
                    <div style={{ fontSize: 16 }}>Xác nhận xóa vĩnh viễn các mục đã được chọn và
                        tất cả thông tin trong đó ?</div>
                </Modal>
                <HeaderLayout></HeaderLayout>
                {isModalOpen === true ?
                    <Modal title="Nhập Mã Bảo Mật" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                        <Input.Password onChange={changePassword}></Input.Password>
                    </Modal>
                    : <div className={styles.container}>
                        <div className={styles.listItemCardContent}>
                            <div className={styles.titleContent}>
                                <div className={styles.titleContentItem}>
                                    <div className={styles.titleContainer} >Quản lý danh thiếp {data?.length > 0 ? `(${data?.length})` : ''}</div>
                                    <div className={styles.containerSearch}>
                                        <div className='input-wrap'>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill='#8896A6' d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>
                                            <label
                                                for="product-search"
                                                id="input-label"
                                            >
                                                Product Search
                                            </label>
                                            <input
                                                onChange={handleInput}
                                                value={searchVal}
                                                type="text"
                                                name="product-search"
                                                id="product-search"
                                                placeholder="Tìm kiếm"
                                            />
                                            {searchVal?.length > 0 ? <div
                                                onClick={handleClearBtn}
                                            ><img style={{ width: 15, height: 15, cursor: 'pointer' }} src={DeleteIcon} /></div> : null}
                                        </div>
                                        <div className={styles.containerBtn}>
                                            <div className={styles.btnContainerItem} onClick={downLoadAll}>Tải QR</div>
                                            <Link to={`/create`}>
                                                <div className={styles.btnContainerItem}>Thêm</div>
                                            </Link>
                                            <div onClick={showPopUpDelete} className={styles.btnContainerItemDelete} >Xóa</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.listItemCard} style={{ backgroundColor: data.length > 0 ? '#F8FAFB' : '#FFF' }}>
                                {data?.map((item, index) =>
                                    <div className={styles.dataItem} >
                                        <div className={styles.backgroundCard}></div>
                                        <div className="checkbox-wrapper-15" onChange={onChangeCheckbox}>
                                            <input className="inp-cbx" id={item?.id} idc={item?.id} namec={item?.full_name} type="checkbox" style={{ display: 'none' }} />
                                            <label className="cbx" for={item?.id}>
                                                <span>
                                                    <svg width="12px" height="9px" viewbox="0 0 12 9">
                                                        <polyline points="1 5 4 8 11 1"></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                        <Link to={`/card/${window.btoa(item.id)}`}>
                                            <div key={index} className={styles.cardItem} style={{ backgroundImage: `url(${backgroundImage})` }}>
                                                {/* <div className={styles.deleteCard}>
                                                    <Link to='/listnamecard'>
                                                        <Button onClick={() => handleDelete(item.id)} className={styles.deleteBtn}>Xóa thẻ</Button>
                                                    </Link>
                                                </div> */}

                                                <div className={styles.listBtn}>
                                                    <Link to='/listnamecard'>
                                                        <img onClick={() => handleDownload(item.id, item.full_name)} className={styles.downLoadBtn} src={downloadIcon} />
                                                    </Link>
                                                    <Link to={`/edit/${window.btoa(item.id)}`}>
                                                        <img src={editIcon} className={styles.editBtn} />
                                                    </Link>
                                                </div>
                                                <div className={styles.cardImage}>
                                                    <img className={styles.imageItem} style={{ backgroundImage: item?.image ? `url( https://namecard.mkvision.com/api/v1/downloads/images/${item?.image})` : `url(https://cdn-icons-png.flaticon.com/512/149/149071.png)` }} />
                                                </div>
                                                <div className={styles.cardName}>
                                                    <div className={styles.nameText}>{item.full_name}</div>
                                                    <div className={styles.desText}>{item.description}</div>
                                                    <div className={styles.infoRole}>{item.company}</div>
                                                </div>
                                                <div className={styles.cardInfo}>
                                                    <Link onClick={() => sendEmail(item?.gmail)} to={`/listnamecard`}>
                                                        <img src={gmailIcon} className={styles.infofleet}></img>
                                                    </Link>
                                                    <Link onClick={() => callMobile(item?.phone)} to={`/listnamecard`}>
                                                        <img src={phoneIcon} className={styles.infofleet}></img>
                                                    </Link>
                                                    <Link onClick={() => locationSearch(item?.address)} to={`/listnamecard`}>
                                                        <img src={locationIcon} className={styles.infofleet}></img>
                                                    </Link>
                                                    <Link to={`/listnamecard`}>
                                                        <div style={{ height: "50px", width: "50px", padding: ' 9px 10px 9px 9px', borderRadius: '8px', background: '#E7EFFF', alignItems: 'center' }}>
                                                            <QRCode
                                                                id={`qr-gen${item.id}`}
                                                                size={100}
                                                                style={{ height: "33px", width: "33px" }}
                                                                value={`${window.location.hostname}/card/${window.btoa(item.id)}`}
                                                                viewBox={`0 0 256 256`}
                                                            />
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className={styles.cardBtn}>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )}
                                {data?.length === 0 ? <img style={{ maxWidth: '90%' }} src={n404Icon} /> : null}
                            </div>
                        </div>
                    </div>}
                <FooterLayout></FooterLayout>
            </div>
        </Spin>
    );
}
export default ListNameCard;
