
import TooltipParagraph from '../utils/func';
import linkIcon from '../assets/images/linkIcon.svg';

function Web(props) {
    const goToWeb = (data) => {
        window.open("//" + data, '_blank');
     }
    return (
        <div onClick={() => goToWeb(props.fleet)} className="about" >
            <div className="qr-code">
                <div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
                    <img src={linkIcon} />
                    <div style={{ marginLeft: 10, width: 180 }}>
                        <TooltipParagraph>
                            {props.fleet}
                        </TooltipParagraph>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Web;