import { useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
const getSrcFromFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => resolve(reader.result);
  });
};
const UploadRender = (props) => {
  const [fileList, setFileList] = useState(
    props?.image ?   [{
      url: `https://namecard.mkvision.com/api/v1/downloads/images/${props?.image}`
    }] : []);

  const onChange = ({ fileList: newFileList }) => { 
    setFileList(newFileList);
    window.localStorage.setItem('image',JSON.stringify(newFileList)) 
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };
  return (
    <ImgCrop showGrid rotationSlider aspectSlider showReset>
      <Upload
        action="https://namecard.mkvision.com/api/v1/uploads/images"
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
      >
        {fileList.length < 1 && '+ Upload'}
      </Upload>
    </ImgCrop>
  );
};
export default UploadRender;