import TooltipParagraph from '../utils/func';
import linkIcon from '../assets/images/phonee.svg';

function Phone(props){
    const callMobile = (data) => {
        window.open(`tel:${data}`);
     }
 
    return (
        <div onClick={() => callMobile(props.phone)} className="about">
             <div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
                    <img src={linkIcon} />
                    <div style={{ marginLeft: 10, width: 180 }}>
                        <TooltipParagraph>
                            {props.phone}
                        </TooltipParagraph>
                    </div>
                </div>
        </div>
    );
}
export default Phone;