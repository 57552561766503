import CTA from './CTA'
function Profile(props){
    return(
        <div className="profile">
            <div className="profile-img">
                <img style={{backgroundImage:  props?.data?.image ? `url( https://namecard.mkvision.com/api/v1/downloads/images/${props?.data?.image})` : `url(https://cdn-icons-png.flaticon.com/512/149/149071.png)`}}   />
            </div>
            <div className="profile-info">
                <p className="profile-name">{props?.data?.full_name}</p>
                <p className="profile-occupation">{props?.data?.description}</p>
                <p className="profile-website">{props?.data?.company}</p>
            </div>

            {props?.falieddata? null : <CTA data={props.data}/>}
        </div>
    );
}
export default Profile;