import Footer from '../componentLayouts/Footer/index';

function FooterLayout({ children }) {
    return (
        <div>
            <Footer />
            <div className="container"></div>
        </div>
    );
}

export default FooterLayout;
