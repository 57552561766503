import HeaderLayout from "../../components/Layout/HeaderOnlyLayout";
import FooterLayout from '../../components/Layout/FooterOnlyLayout';
import { Link } from 'react-router-dom';
import styles from './Home.module.scss';
import bgImage1 from '../../assets/images/bg1.svg'
export function Home() {

    return (
        <div className={styles.pageHome}>
            <HeaderLayout>
            </HeaderLayout>
            {/* <div className={styles.container}>
                    <Link to='/create'>
                        <div className={styles.btnCreate}>
                            Start Designing a Business Card
                        </div>
                    </Link>
            </div> */}
            <img src={bgImage1} style={{width: '100%', minHeight: '100vh'}}/>
            <FooterLayout></FooterLayout>
        </div>
    );
}
export default Home;
