import React from "react";
import downloadIcon from '../assets/images/qrdownloadd.svg'

export default function Download(props) {

  const handleDownload = () => {
    let url = `https://namecard.mkvision.com/api/v1/card/vcf/${props?.data?.id}`
      window.open(url, "_blank")
  }

  return (
    <div >
      <button className="cta-btn cta-btn-linkedin" onClick={handleDownload}>
        <span className="cta-btn-icon">
          <img src={downloadIcon} style={{width: 16, height: 16,}} />
        </span>
        <span  className="cta-btn-text">Tải File VCF</span>
      </button>
    </div>
  );
}
