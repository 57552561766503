import Download from './Download'
import * as downloadAsPng from 'save-svg-as-png'

function CTA(props) {

    const downloadQRCode = () => {
        downloadAsPng.saveSvgAsPng(document.getElementById("qr-gen"), `${props.data.full_name}_qrcode`,{scale: 10})
    };

    return (
        <div className="cta">
            <Download data={props.data}>
            </Download>
        </div>
    );
}
export default CTA;