import axios from 'axios';

const BASE_URL = 'https://namecard.mkvision.com';


export const fetchGetFromAPI = async (url,options) => {
  let data
  await axios.get(`${BASE_URL}${url}`, options)
  .then(function (response) {
    data = response?.data
  })
  .catch(function (error) {
    if (error.message === 'Request failed with status code 401') {
      window.localStorage.removeItem('token')
    }
    data = error?.response?.data
  });
  return data;
};

export const fetchPostFromAPI = async (url, body, header) => {
  let data
  await axios.post(`${BASE_URL}/${url}`, body, header)
  .then(function (response) {
    data = response?.data
  })
  .catch(function (error) {
    if (error.message === 'Request failed with status code 401') {
      window.localStorage.removeItem('token')
    }
    data = error?.response?.data
  });
  return data;
};

export const fetchPutFromAPI = async (url, body, header) => {
  let data
  await axios.put(`${BASE_URL}/${url}`, body, header)
  .then(function (response) {
    data = response?.data
  })
  .catch(function (error) {
    if (error.message === 'Request failed with status code 401') {
      window.localStorage.removeItem('token')
    }
    data = error?.response?.data
  });
  return data;
};


export const fetchDeleteFromAPI = async (url, header) => {
  let data
  await axios.delete(`${BASE_URL}/${url}`, header)
  .then(function (response) {
    data = response?.data
  })
  .catch(function (error) {
    if (error.message === 'Request failed with status code 401') {
      window.localStorage.removeItem('token')
    }
    data = error?.response?.data
  });
  return data;
};
