import styles from './Header.module.scss';
import { Link } from 'react-router-dom'
import Menu from '../../../Menu';
import React, { useEffect, useState } from 'react';
import LogoImage from '../../../../assets/images/Logo.svg'


function Header() {

    const [check, setCheck] = useState(true);

    const handlegoToWeb = () => {
        window.open("http://mkvision.com/", "_blank")
    }

    const handleLogout = () => {
        window.localStorage.removeItem('token')
        window.location.reload()
    }

    return (
        <header className={styles.wrapper}>
            <div className={styles.wrapperItem}>
                <><div className={styles.fullmenu} style={{ display: 'flex' }}>
                    <Link to='/'>
                        <div className={styles.itemHeader}>
                            <img className={styles.itemImg} src={LogoImage} />
                        </div>
                    </Link>
                </div><div className={styles.fullmenu} style={{ display: 'flex' }}>
                        <div className={styles.itemHeader}>
                            <div onClick={handlegoToWeb} className={styles.text}>Về chúng tôi</div>
                        </div>
                        <Link to='/listnamecard'>
                            <div className={styles.itemHeader}>
                                <div className={styles.text}>Quản lý</div>
                            </div>
                        </Link>
                        {window.localStorage.getItem('token') ? <div className={styles.itemHeader}>
                            <div onClick={handleLogout} className={styles.text}>Đăng xuất</div>
                        </div> : null}
                    </div></> <div className={styles.menu}><Menu></Menu></div>
            </div>
        </header>
    );
}

export default Header;
