import { Button, Divider, Dropdown, Space, theme } from 'antd';
import { Link } from 'react-router-dom'
import React from 'react';
import MenuIcon from '../assets/images/MenuIcon.jpg'
const items = [
    {
        key: '1',
        label: (
            <Link to='/' >
                <a style={{color: '#000'}} target="_blank" rel="noopener noreferrer" >
                    MK Vision
                </a>
            </Link>
        ),
    },
    {
        key: '2',
        label: (
            <a target="_blank" rel="noopener noreferrer" href="http://mkvision.com/">
                Về chúng tôi
            </a>
        ),
    },
    {
        key: '3',
        label: (
            <Link to='/listnamecard' >
                <a style={{color: '#000'}} target="_blank" rel="noopener noreferrer" >
                Quản lý
                </a>
            </Link>
        ),
    },
    {
        key: '4',
        label: (
            <div rel="noopener noreferrer"  onClick={() => {localStorage.removeItem('token'); window.location.reload()}}>
                Đăng xuất
            </div>
        ),
    },
];

const itemsNotToken = [
    {
        key: '1',
        label: (
            <Link to='/' >
                <a style={{color: '#000'}} target="_blank" rel="noopener noreferrer" >
                    MK Vision
                </a>
            </Link>
        ),
    },
    {
        key: '2',
        label: (
            <a target="_blank" rel="noopener noreferrer" href="http://mkvision.com/">
                Về chúng tôi
            </a>
        ),
    },
    {
        key: '3',
        label: (
            <Link to='/listnamecard' >
                <a style={{color: '#000'}} target="_blank" rel="noopener noreferrer" >
                Quản lý
                </a>
            </Link>
        ),
    },
];
const App = () => {
    const menuStyle = {
        boxShadow: '1px solid #ccc',
    };
    return (
        <Dropdown
            menu={{ items: localStorage.getItem('token') ?
                items : itemsNotToken
            }}
            dropdownRender={(menu) => (
                <div >
                    {React.cloneElement(menu, {
                        style: menuStyle,
                    })}
                    <Divider
                        style={{
                            margin: 0,
                        }}
                    />
                    <Space
                        style={{
                            padding: 8,
                        }}
                    >
                    </Space>
                </div>
            )}
        >
            <a onClick={(e) => e.preventDefault()}>
                <Space style={{
                    color: "#fff", fontSize: '30px',
                    marginLeft: '-17px',
                }}>
                    <img style={{width: 50, height: 50}} src={MenuIcon} />
                </Space>
            </a>
        </Dropdown>
    );
};
export default App;