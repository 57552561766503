import styles from './Create.module.scss';
import HeaderLayout from '../../components/Layout/HeaderOnlyLayout';
import FooterLayout from '../../components/Layout/FooterOnlyLayout';
import { Button, Modal, Form, Input, notification, DatePicker } from 'antd';
import Upload from './upload';
import React, { useEffect, useState } from 'react';
import { fetchPostFromAPI } from '../../utils/fetchFromAPI';
import moment from 'moment'


function Create() {
    const { TextArea } = Input;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [checkSubmit, setCheckSubmit] = useState(false);
    const [password, setPassword] = useState('');
    const [changeData, setChangeData] = useState('');
    const [checkRoleWrong, setCheckRoleWrong] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (window.localStorage.getItem('token')) {
            setIsModalOpen(false)
        }
        else {
            setIsModalOpen(true)
        }
    }, [window.localStorage.getItem('token')]);


    const handleOk = async () => {
        const data = await fetchPostFromAPI('api/v1/auth', {
            "username": "admin",
            "password": password
        })
        console.log("data", data)
        if (data.token) {
            window.localStorage.setItem("token", data.token);
            setIsModalOpen(false);
        }
        else {
            openNotificationFail('Mã bảo mật không đúng')
        }
    };


    const changePassword = (event) => {
        setPassword(event.target.value)
    }

    const handleChangeData = (e) => {
        setChangeData(e.target.value)
    }

    useEffect(() => {
        if ((changeData === '' || changeData === undefined || changeData === null) && checkSubmit === true) {
            setTimeout(() => {
                setCheckRoleWrong(true)
            }, 500)
        }
        else {
            setCheckRoleWrong(false)
        }
    }, [changeData, checkSubmit]);

    const handleCancel = () => {
        setIsModalOpen(true);
        window.location.href = '/';
    };

    const clickSubmit = () => {
        setCheckSubmit(true)
    }

    const openNotificationSuccess = () => {
        notification.success({
            message: 'Notification',
            description:
                'Create success',
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    const openNotificationFail = (mess) => {
        notification.error({
            message: 'Notification',
            description: mess,
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    const handleDelete = (id) => {
        console.log('delete', id)
    }
    const onFinish = async (values) => {
        console.log('Success:', values);
        const url = 'api/v1/card'
        const data = await fetchPostFromAPI(url, {
            "full_name": values.full_name,
            "birthday": moment(values.birthday).format("YYYY-MM-DD"),
            "domain": values.domain,
            "company": values.company,
            "description": values.description,
            "phone": values.phone,
            "email": values.email,
            "address": values.address,
            "facebook": values.facebook,
            image: JSON.parse(window.localStorage.getItem('image')) ? JSON.parse(window.localStorage.getItem('image'))[0].response.result : ''
        }, {
            headers: {
                'token': window.localStorage.getItem('token'),
            }
        })
        if (data.result === "success") {
            window.localStorage.removeItem('image')
            form.resetFields();
            openNotificationSuccess()
        }
        else if (data === 'jwt fail') {
            window.localStorage.removeItem('token')
        }
    };

    const onChange = (date, dateString) => {
        console.log(date, dateString);
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div>
            <HeaderLayout>
            </HeaderLayout>
            {isModalOpen === true ?
                <Modal title="Enter private key" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Input.Password onChange={changePassword}></Input.Password>
                </Modal> :
                <div className={styles.container}>
                    <div className={styles.form}>
                        <div className={styles.titlePage}>Tạo thẻ mới</div>
                        <Form
                            name="basic"
                            labelCol={{ span: 8 }}
                            layout="vertical"
                            form={form}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <div className={styles.content}>
                                <div className={styles.detail}>
                                    <div className={styles.textDetail}>
                                        Thông tin thẻ
                                    </div>
                                    <Form.Item
                                        label="Tải ảnh"
                                        name="uploadImage"
                                        style={{ marginBottom: 22 }}
                                    >
                                        <Upload ></Upload>
                                    </Form.Item>
                                    {checkRoleWrong === true ? <div className={styles.layoutReturn}></div> : null}
                                    <Form.Item
                                        label="Họ và tên"
                                        name="full_name"
                                        rules={[{ required: true, message: 'Xin hãy thêm Họ và tên!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Ngày sinh"
                                        name="birthday"
                                    >
                                        <DatePicker format="DD/MM/YYYY" onChange={onChange} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Công ty"
                                        name="company"
                                        rules={[{ required: true, message: 'Xin hãy thêm tên Công ty!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className={styles.contact}>
                                    <div className={styles.textDetail}>
                                        Liên hệ
                                    </div>
                                    <Form.Item
                                        label="Chức danh"
                                        name="description"
                                        rules={[{ required: true, message: 'Xin hãy thêm chức danh!' }]}
                                    >
                                        <TextArea onChange={handleChangeData} rows={2} maxLength={200} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Số điện thoại"
                                        name="phone"
                                        rules={[{ required: true, message: 'Xin hãy thêm số điện thoại!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[{ required: true, message: 'Xin hãy thêm Email!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Website"
                                        name="domain"
                                        rules={[{ required: true, message: 'Xin hãy thêm Website!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Địa chỉ"
                                        name="address"
                                        rules={[{ required: true, message: 'Xin hãy thêm Địa chỉ!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={styles.submit} >
                                <Button onClick={clickSubmit} className={styles.btnSubmit} type="primary" htmlType="submit">
                                    Tạo
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>}
            <FooterLayout></FooterLayout>
        </div>
    );
}
export default Create;
