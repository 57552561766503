
import TooltipParagraph from '../utils/func';
import linkIcon from '../assets/images/locationn.svg';


function Address(props){
    const locationSearch = (data) => {
        window.open("//" + "google.com/search?q=" + data, '_blank');
   }
    return(
        <div onClick={() => locationSearch(props.address)} className="about" >
            <div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
                    <img src={linkIcon} />
                    <div style={{ marginLeft: 10, width: 180 }}>
                        <TooltipParagraph>
                            {props.address}
                        </TooltipParagraph>
                    </div>
                </div>
        </div>
    );
   
}
export default Address;